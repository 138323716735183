import React from "react"
// import { Link } from "gatsby"

// Styles
import styles from "./award.module.sass"

// Iccons
import AwardsYear from "../../../../icons/awards/award-year.svg"
import ArrowRight from "../../../../icons/arrow-right.svg"

const Award = ({ children, year, link, description }) => (
  <div className={styles.awardWrapper}>
    <div className={styles.award}>
      <div className={styles.year}>
        <AwardsYear />
        <span>{year}</span>
      </div>
      <div className={styles.border}></div>
      <a
        href={link}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.link}
      />
      <div className={styles.content}>
        {children}
        <div className={styles.contentHover}>
          {description}
          <button className={styles.button + " button button-arrow"}>
            view more <ArrowRight />
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default Award
