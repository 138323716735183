import React from "react"

// Components
import StyledCard from "../../Global/StyledCard/StyledCard"

// Styles
import styles from "./gf-clutch.module.sass"

// Icons
import GoodFirmsIcon from "../../../icons/servicesPage/awards/good-firms.svg"
import GoodFirmsBigIcon from "../../../icons/servicesPage/awards/good-firms-big.svg"
import ClutchIcon from "../../../icons/servicesPage/awards/clutch.svg"
import ClutchBigIcon from "../../../icons/servicesPage/awards/clutch-big.svg"
import StarIcon from "../../../icons/servicesPage/awards/star.svg"

const GFClutchSection = () => {
  return (
    <div className="container">
      <div className="row">
        <div className={styles.wrapper}>
          <div className={styles.card}>
            <StyledCard
              external
              type="hovered"
              link="https://www.goodfirms.co/company/madfish-solutions"
            >
              <div className={styles.content}>
                <div className={styles.icon}>
                  <GoodFirmsBigIcon />
                </div>
                <GoodFirmsIcon />
                <h3>We are on Good firms</h3>
                <div className={styles.stars}>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </div>
                <div className={"button button-primary " + styles.button}>
                  <span className="content">View Profile</span>
                </div>
              </div>
            </StyledCard>
          </div>
          <div className={styles.card}>
            <StyledCard
              external
              type="hovered"
              link="https://clutch.co/profile/madfishsolutions"
            >
              <div className={styles.content}>
                <div className={styles.icon}>
                  <ClutchBigIcon />
                </div>
                <ClutchIcon />
                <h3>We are on Clutch</h3>
                <div className={styles.stars}>
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </div>
                <div className={"button button-primary " + styles.button}>
                  <span className="content">View Profile</span>
                </div>
              </div>
            </StyledCard>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GFClutchSection
