import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Components
import Award from "./Award/Award"

// Styles
import styles from "./awards.module.sass"

// Content
const awardsArr = [
  {
    link:
      "https://www.facebook.com/distributedlab/posts/2097019207195826?__tn__=-R",
    description:
      'First Place with the "Decentralized Internet (Mesh Network)" project.',
  },
  {
    link:
      "https://www.facebook.com/kravchenkopo/posts/2369112379765486?__tn__=H-R",
    description:
      'First Place with "ASIC resistance algo" project which use in render algos as a hash functions.',
  },
  {
    link:
      "https://www.facebook.com/distributedlab/posts/2463032337261176?__tn__=-R",
    description:
      'First place with "Compiler from Solidity to NearProtocol language" project.',
  },
  {
    link:
      "https://bel.biz/ecosystem/v-imaguru-startup-hub-proshel-pervyj-uton-hack-european-digital-blockchain-hackathon/",
    description:
      'Winners in "Soe Golf" and "Best Dapp" with projects: "Shortest blockchain " and " The Game on Tron".',
  },
  {
    link:
      "https://everstake.one/blog/the-first-eastern-european-tezos-hackathon-recap-great-solutions-valuable-experiences-and-good-memor",
    description:
      'First and Third place with "Solidity to Ligo compiler" and "Browser Extension wallet for Tezos" projects.',
  },
]

const AwardsScreen = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "awards" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section className={"section"}>
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <div className="heading">
            <h2>Awards</h2>
            <p>
              We do not only consult, design & engineer successful software
              solutions, but we are also the mad enthusiasts who love challenges
              and hackathons
            </p>
          </div>
          <div className={styles.wrapper}>
            {data.allFile.edges.map((image, index) => (
              <Award
                year={image.node.base.split("-")[0]}
                key={image.node.base.split(".")[0]}
                link={awardsArr[index].link}
                description={awardsArr[index].description}
              >
                <Img
                  fluid={image.node.childImageSharp.fluid}
                  alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
                />
              </Award>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AwardsScreen
